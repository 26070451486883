import React, { useState, useEffect } from "react";
import ConcreteBox from "./ConcreteBox";
import axios from "axios";
import { AbstractData, ConcreteData } from "../data";

const Hero = () => {
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [randomConcrete, setRandomConcrete] = useState("");
  const [randomAbstract, setRandomAbstract] = useState("");
  const [selectedConcrete, setSelectedConcrete] = useState("Electronics");
  const [selectedAbstract, setSelectedAbstract] = useState(
    "Emotions and Feelings"
  );

  const HTTP = "http://localhost:8080/chat";

  const handleSubmit = () => {
    const filteredData = ConcreteData.find(
      (data) => data.title === selectedConcrete
    );
    const randomWordIndex = Math.floor(
      Math.random() * filteredData.words.length
    );
    let randomWord = filteredData.words[randomWordIndex];
    const filteredData1 = AbstractData.find(
      (data) => data.title === selectedAbstract
    );
    const randomWordIndex1 = Math.floor(
      Math.random() * filteredData1.words.length
    );
    let randomWord1 = filteredData1.words[randomWordIndex1];
    console.log(randomWord, randomWord1);
    setRandomAbstract(randomWord1);
    setRandomConcrete(randomWord);
    const newPrompt = `Generate surprising, imaginative, light-hearted, contradicting prompts to encourage lateral, playful and imaginative thinking using the provided abstract and concrete concepts data set.\n  word concrete = ${randomWord}, \n word abstract =  ${randomWord1}, \n only one sentence`;
    axios
      .post(`${HTTP}`, { prompt: newPrompt })
      .then((res) => {
        setResponse(res.data.content);
        console.log(res);
        setPrompt("")

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const filteredData = ConcreteData.find(
      (data) => data.title === selectedConcrete
    );
    const randomWordIndex = Math.floor(
      Math.random() * filteredData.words.length
    );
    let randomWord = filteredData.words[randomWordIndex];
    const filteredData1 = AbstractData.find(
      (data) => data.title === selectedAbstract
    );
    const randomWordIndex1 = Math.floor(
      Math.random() * filteredData1.words.length
    );
    let randomWord1 = filteredData1.words[randomWordIndex1];
    console.log(randomWord, randomWord1);
    setRandomAbstract(randomWord1);
    setRandomConcrete(randomWord);
    const newPrompt = `Generate surprising, imaginative, light-hearted, contradicting prompts to encourage lateral, playful and imaginative thinking using the provided abstract and concrete concepts data set.\n  word concrete = ${randomWord}, \n word abstract =  ${randomWord1}, \n only one sentence`;
    axios
      .post(`${HTTP}`, { prompt: newPrompt })
      .then((res) => {
        setResponse(res.data.content);
        console.log(res);
        setPrompt("")
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const renderWords = (a, abstract, concrete) => {
    const words = a.split(' ');
    return (
      <div className="flex flex-wrap">
        {words.map((word, index) => (
          <span
            key={index}
            className={`mr-3 ${word === abstract.toLowerCase() ? 'bg-purple_700 text-white rounded-[20px] px-[10px] py-[2px] f-dmmono-r leading-[65px]' : ''} ${word === concrete.toLowerCase() ? 'bg-[#E1FF22] text-white rounded-[20px] px-[10px] py-[2px] f-dmmono-r leading-[65px]' : ''} `}
          >
            {word}{' '}
          </span>
        ))}
      </div>
    );
  };

  return (
    <section className="pt-14 md:pt-[120px] mb-40 md:mb-[378px] w-full px-4">
      <div className="max-w-[1300px] w-full mx-auto flex flex-col lmd:flex-row gap-10 md:gap-20">
        <div className="w-full lmd:w-[60%]">
          <h1 className="text-black_900 text-center xmd:text-start f-anvenirnext-m text-5xl xsm:text-[65px] leading-[72px] xsm:leading-[80px]">
            {response
              ? renderWords(response, randomAbstract, randomConcrete)
              : null}
          </h1>
        </div>
        <div className="flex-1 w-full lmd:w-[40%] flex justify-center lmd:justify-end">
          <ConcreteBox handleSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
